<template>
  <featured
  ></featured>
</template>

<script>
import Featured from '@/views/ShoppingMail/Featured/index.vue';
export default {
  components: {
    Featured,
  }
}
</script>

