var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_featured_index" },
    [
      _vm.home
        ? _c("home", { on: { handlePushNavigation: _vm.handlePushNavigation } })
        : _vm._e(),
      !_vm.home
        ? _c("div", { staticClass: "page d-flex flex-column align-start" }, [
            _c(
              "div",
              { staticClass: "sticky_group bbox flex-shrink-0 padding_x" },
              [
                _c("sub-navigation", {
                  staticClass: "sub_navigation py-10",
                  attrs: { navigation: _vm.navigation },
                  on: { handleChangeNavigation: _vm.handleChangeNavigation },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "main_group" },
              [
                _vm.page_type && _vm.page_type === "task"
                  ? _c("task-details", {
                      staticClass: "padding_x",
                      attrs: { task_id: _vm.page_id, payment: _vm.payment },
                      on: { handlePushNavigation: _vm.handlePushNavigation },
                    })
                  : _vm._e(),
                _vm.page_type && _vm.page_type === "task"
                  ? _c("teaching-package-details", {
                      staticClass: "padding_x",
                      attrs: { page_id: _vm.page_id, payment: _vm.payment },
                      on: { handlePushNavigation: _vm.handlePushNavigation },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "nomore" }, [_vm._v("————  已经到底啦  ————")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }