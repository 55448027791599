var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass:
        "views_shopping_mail_featured_carousel_index d-flex align-center justify-space-between bbox px-20",
    },
    [
      _c("div", {
        staticClass: "button prev",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.doPrev.apply(null, arguments)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "main_group" },
        [
          _c("minor", {
            ref: "prev",
            staticClass: "minor_group",
            style: { right: `${_vm.major_width - _vm.cover_width}px` },
            attrs: {
              width: _vm.minor_width,
              height: _vm.minor_height,
              list: _vm.list,
              initialSlide: -1,
            },
            on: { handleClick: _vm.handleClick },
          }),
          _c("major", {
            ref: "major",
            staticClass: "major_group",
            attrs: {
              width: _vm.major_width,
              height: _vm.major_height,
              list: _vm.list,
            },
            on: {
              handleClickPagination: _vm.handleClickPagination,
              handleClick: _vm.handleClick,
            },
          }),
          _c("minor", {
            ref: "next",
            staticClass: "minor_group",
            style: { left: `${_vm.major_width - _vm.cover_width}px` },
            attrs: {
              width: _vm.minor_width,
              height: _vm.minor_height,
              list: _vm.list,
              initialSlide: 1,
            },
            on: { handleClick: _vm.handleClick },
          }),
        ],
        1
      ),
      _c("div", {
        staticClass: "button next",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.doNext.apply(null, arguments)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }