<template>
  <div
    class="views_shopping_mail_featured_index"
  >
    <home
      v-if="home"
      @handlePushNavigation="handlePushNavigation"
    ></home>
    <div class="page d-flex flex-column align-start"
      v-if="!home"
    >
      <div class="sticky_group bbox flex-shrink-0 padding_x">
        <sub-navigation
          :navigation="navigation"
          class="sub_navigation py-10"
          @handleChangeNavigation="handleChangeNavigation"
        ></sub-navigation>
      </div>
      <div class="main_group">
        <task-details
          v-if="page_type && page_type === 'task'"
          :task_id="page_id"
          :payment="payment"
          class="padding_x"
          @handlePushNavigation="handlePushNavigation"
        ></task-details>
        <teaching-package-details
          v-if="page_type && page_type === 'task'"
          :page_id="page_id"
          :payment="payment"
          class="padding_x"
          @handlePushNavigation="handlePushNavigation"
        ></teaching-package-details>
      </div>
    </div>
    <div class="nomore">————  已经到底啦  ————</div>
  </div>
</template>

<script>
import Home from './Home.vue';
import SubNavigation from '@/views/ShoppingMail/Modules/Common/SubNavigation/index.vue';
import TeachingPackageDetails from '@/views/ShoppingMail/TeachingPackage/Details.vue';
import TaskDetails from '@/views/ShoppingMail/Task/Details.vue';
export default {
  components: {
    Home,
    SubNavigation,
    TeachingPackageDetails,
    TaskDetails,
  },
  props: {
    navigation: {
      type: Array,
      default: ()=>{
        return [];
      }
    }
  },
  computed: {
    home(){
      const {navigation} = this;
      if(!navigation || navigation.length<2){
        return true;
      }
      return false;
    },
    page_type(){
      const {navigation} = this;
      if(!navigation || navigation.length<2){
        return true;
      }
      return navigation[navigation.length - 1].type;
    },
    page_id(){
      const {navigation} = this;
      if(!navigation || navigation.length<2){
        return true;
      }
      const last_item = navigation[navigation.length - 1];
      const type = last_item.type;
      switch(type){
        case('page'):
          return false;
        case('teaching_package'):
          return last_item.package_data.id;
        case('task'):
          return last_item.task_data.id;
        default:
          return false;
      }
    },
    payment(){
      const {navigation} = this;
      if(!navigation || navigation.length<2){
        return undefined;
      }
      const last_item = navigation[navigation.length - 1];
      const payment = last_item.payment;
      return payment;
    },
  },
  methods: {
    handlePushNavigation(data){
      this.$emit('handlePushNavigation', data);
    },
    handleChangeNavigation(data){
      this.$emit('handleChangeNavigation', data);
    },
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_featured_index{
  width: 100%;
  height: 100%;
  background-color: #fff;
  .nomore{
    width: 100%;
    font-size: 16px;
    color: #D5D9DB;
    line-height: 21px;
    text-align: center;
    padding: 50px 0;
  }
  .main_group{
    width: 100%;
  }
  .padding_x{
    padding: {
      left : 40px;
      right: 40px;
    }
  }
  .page{
    // @include scrollbar;
    // overflow: auto;
    width: 100%;
    height: 100%;
    position: relative;
  }

}
</style>
