<template>
  <div
    class="views_shopping_mail_featured_carousel_index d-flex align-center justify-space-between bbox px-20"
    ref="wrapper"
  >
    <div class="button prev"
      @click.stop="doPrev"
    ></div>
    <div
      class="main_group"
    >
      <minor
        class="minor_group"
        :style="{right: `${major_width - cover_width}px`}"
        :width="minor_width"
        :height="minor_height"
        :list="list"
        :initialSlide="-1"
        @handleClick="handleClick"
        ref="prev"
      ></minor>
      <major
        class="major_group"
        :width="major_width"
        :height="major_height"
        :list="list"
        @handleClickPagination="handleClickPagination"
        @handleClick="handleClick"
        ref="major"
      ></major>
      <minor
        class="minor_group"
        :style="{left: `${major_width - cover_width}px`}"
        :width="minor_width"
        :height="minor_height"
        :list="list"
        :initialSlide="1"
        @handleClick="handleClick"
        ref="next"
      ></minor>
    </div>
    <div class="button next"
      @click.stop="doNext"
    ></div>
  </div>
</template>

<script>
/**
 * 宽度计算:
 * [设计稿]
 * 总宽度 416*2 + 466  = 1298
 * 次轮播 416
 * 主轮播 488
 * 次轮播被遮盖 10
 *
 * [不缩放, 不在总宽度计算中]
 * 两侧按钮 30
 * 两侧按钮空白 20
 * 最外侧留白 40
 *
 */
import Major from './Major.vue';
import Minor from './Minor.vue';
export default {
  components: {
    Major,
    Minor,
  },
  props: {
    list: {
      type: Array,
      default: ()=>{
        return [];
      }
    },
  },
  mounted(){
    this.init();
  },
  data(){
    return{
      major_width : undefined,
      major_height: undefined,
      minor_width : undefined,
      minor_height: undefined,
      cover_width : undefined,
      // list: [
      //   {name:1},
      //   {name:2},
      //   {name:3},
      //   {name:4},
      //   {name:5},
      // ]
    }
  },
  methods: {
    init(){
      this.getSize();
    },
    getSize(){
      const wrapper = this.$refs.wrapper;
      const {clientWidth:wrapper_width} = wrapper;
      // // // console.log(wrapper_width);
      // 计算用宽度 = 总宽度 - 最外留白40*2 - 按钮及按钮留白(20+30)*2
      const actural_total_width = wrapper_width - 80 - 100;
      const major_width = this.calculativeWidth({
        width: 488,
        actural_total_width,
      })
      const minor_width = this.calculativeWidth({
        width: 416,
        actural_total_width,
      })
      const cover_width = this.calculativeWidth({
        width: 10,
        actural_total_width,
      })
      const major_height = this.calculativeHeight({
        width: major_width,
      })
      const minor_height = this.calculativeHeight({
        width: minor_width,
      })

      this.major_width  = major_width;
      this.major_height = major_height;
      this.minor_width  = minor_width;
      this.minor_height = minor_height;
      this.cover_width  = cover_width;


    },
    calculativeWidth(params={}){
      // width:需要转换部位的设计稿宽度
      // actural_total_width: 实际总宽度
      const {width, actural_total_width} = params;
      const draft_width = 1298;
      const acturl = (width * actural_total_width) / draft_width;
      return acturl; // 转换后的实际宽度
    },
    /**
     * 按照16:9转换高度
     */
    calculativeHeight(params={}){
      const {width} = params;
      const height = (9 * width) / 16;
      return height;
    },
    handleClickPagination(activeIndex){
      const {$refs} = this;
      const {prev, next} = $refs;
      let prev_index = 0;
      let next_index = 0;
      prev_index = activeIndex - 1;
      next_index = activeIndex + 1;
      prev.slideTo(prev_index);
      next.slideTo(next_index);
    },
    doPrev(){
      const {$refs} = this;
      const {prev, next, major} = $refs;
      prev.slidePrev();
      next.slidePrev();
      major.slidePrev();
    },
    doNext(){
      const {$refs} = this;
      const {prev, next, major} = $refs;
      prev.slideNext();
      next.slideNext();
      major.slideNext();
    },
    handleClick(item){
      this.$emit('handleClick', item);
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_featured_carousel_index{
  width: 100%;
  max-width: 1200px;
  .button{
    @include background(10px, 17px);
    width: 30px;
    height: 76px;
    border-radius: 4px;
    background: {
      color: #f9f9f9;
    }
    &:hover{
      background: {
        color: #ebebeb;
      }
    }
    &.prev{
      background: {
        image: url(~@/assets/images/shopping_mail/carousel/btn_prev.png);
      }
    }
    &.next{
      background: {
        image: url(~@/assets/images/shopping_mail/carousel/btn_next.png);
      }
    }
  }
  .main_group{
    position: relative;
  }
  .major_group{
    position: relative;
    z-index: 3;
  }
  .minor_group{
    position: absolute;
    z-index: 2;
    top:50%;
    transform: translateY(-50%);
  }
}
</style>
