<template>
  <div
    class="views_shopping_mail_featured_carousel_major"
    v-if="width && height"
    :style="{width: `${width}px`, height: `${height}px`}"
  >
    <div class="wrapper">
      <swiper ref="mySwiper" :options="swiperOptions"
        class="swiper_group"
        @slideChange="slideChange"
        @click.stop.native="handleClickSwiper()"
      >
        <template
          v-for="(item,index) in list"
        >

          <slide-module
            :key="index"
            :data="item"
            :height="height"
          ></slide-module>
        </template>
      </swiper>
    </div>
    <div class="swiper-pagination major_pagination" slot="pagination"></div>
  </div>
</template>

<script>
import { Swiper } from 'vue-awesome-swiper';
import SlideModule from '@/views/ShoppingMail/Modules/Swiper/Featured/Slide.vue';
import 'swiper/css/swiper.css';
export default {
  props: {
    width: {
      type: Number,
      default: undefined,
    },
    height: {
      type: Number,
      default: undefined,
    },
    list: {
      type: Array,
      default: ()=>{
        return [];
      }
    },
  },
  components: {
    Swiper,
    SlideModule,
  },
  data(){
    return {
      swiperOptions: {
        allowTouchMove: false,
        loopedSlides: 5,
        loopAdditionalSlides: 5,
        effect : 'fade',
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  methods: {
    slideChange(){
      const {activeIndex} = this.swiper;
      this.$emit('handleClickPagination', activeIndex);
    },
    slidePrev(){
      this.swiper.slidePrev();
    },
    slideNext(){
      this.swiper.slideNext();
    },
    handleClickSwiper(){
      const {realIndex} = this.swiper
      this.$emit('handleClick', this.list[realIndex]);
    }
  }

}
</script>

<style lang="scss" scoped>
.views_shopping_mail_featured_carousel_major{
  .wrapper{
    border-radius: 10px;
    overflow: hidden;
  }
  .swiper_group{
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.views_shopping_mail_featured_carousel_major{
  .major_pagination{
    position: relative;
    .swiper-pagination-bullet{
      margin: 0 4px;
      &.swiper-pagination-bullet-active{
        background-color: $main_blue;
      }
    }
    // bottom: -10px;
  }
}
</style>
