var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.width && _vm.height
    ? _c(
        "div",
        {
          staticClass: "views_shopping_mail_featured_carousel_major",
          style: { width: `${_vm.width}px`, height: `${_vm.height}px` },
        },
        [
          _c(
            "div",
            { staticClass: "wrapper" },
            [
              _c(
                "swiper",
                {
                  ref: "mySwiper",
                  staticClass: "swiper_group",
                  attrs: { options: _vm.swiperOptions },
                  on: { slideChange: _vm.slideChange },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleClickSwiper()
                    },
                  },
                },
                [
                  _vm._l(_vm.list, function (item, index) {
                    return [
                      _c("slide-module", {
                        key: index,
                        attrs: { data: item, height: _vm.height },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("div", {
            staticClass: "swiper-pagination major_pagination",
            attrs: { slot: "pagination" },
            slot: "pagination",
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }