<template>
  <div
    class="views_shopping_mail_featured_carousel_minor"
    v-if="width && height"
    :style="{width: `${width}px`, height: `${height}px`}"
  >
    <div class="wrapper">
      <swiper ref="mySwiper" :options="swiperOptions"
        class="swiper_group"
        @click.stop.native="handleClickSwiper()"
      >
        <template
          v-for="(item,index) in list"
        >
          <slide-module
            :key="index"
            :data="item"
            :height="height"
          ></slide-module>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper } from 'vue-awesome-swiper';
import SlideModule from '@/views/ShoppingMail/Modules/Swiper/Featured/Slide.vue';
import 'swiper/css/swiper.css';
export default {
  props: {
    width: {
      type: Number,
      default: undefined,
    },
    height: {
      type: Number,
      default: undefined,
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: ()=>{
        return [];
      }
    },
  },
  components: {
    Swiper,
    SlideModule,
  },
  data(){
    return {
      swiperOptions: {
        allowTouchMove: false,
        loopedSlides: 5,
        loopAdditionalSlides: 5,
        effect : 'fade',
        loop: true,
        initialSlide: this.initialSlide,
        pagination: {
          // el: '.swiper-pagination',
          // clickable: true,
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods:{
    slideTo(index){
      this.swiper.slideTo(index);
    },
    slidePrev(){
      this.swiper.slidePrev();
    },
    slideNext(){
      this.swiper.slideNext();
    },
    handleClickSwiper(){
      const {realIndex} = this.swiper
      this.$emit('handleClick', this.list[realIndex]);
    }
  }

}
</script>

<style lang="scss" scoped>
.views_shopping_mail_featured_carousel_major{
  .wrapper{
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>
